<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <template>
                   Pre-Reqisite Check List<span class="text-danger">*</span><br>
                   <div>
                     <ul style="list-style-type: square !important; color:blue !important; text-align:left !important;">
                       <li v-for="(item, index) in checkLists" :key="index">
                           {{ $i18n.locale === 'bn' ? item.title_bn : item.title }}
                       </li>
                    </ul>
                   </div>
                    <br>
              </template>
              <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <b-row v-for="(setLevel, index) in farmer_request.farmer_level" :key="index">
                  <b-col lg="10" sm="10">
                    <ValidationProvider name="Level" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="5"
                        label-for="level"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          {{ $t('warehouse_service.level')}}<span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          :id="index"
                          v-model="setLevel.level_id"
                          :options="levelList"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                    <b-col lg="1" sm="1">
                        <b-button v-show="index == farmer_request.farmer_level.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                        <b-button v-show="index || ( !index && farmer_request.farmer_level.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </b-col>
                  </b-row><br><br>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { farmerRequestApprove, farmerRequestCheckList } from '../../api/routes'

export default {
  props: {
       id: {
            default: null,
            type: [String, Number]
        },
        warehouse_id: {
            default: null,
            type: [String, Number]
        }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.checkList()
  },
  mounted () {
    core.index()
    this.getLevelList(this.warehouse_id)
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.yes'),
     prerequisit: [],
      dList: [],
      checkLists: [],
      levelList: [],
      farmer_request: {
        farmer_level: [
            {
            level_id: 0,
            level_quantity: 0,
            level_space: ''
            }
        ]
      }
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    }
  },
  methods: {
    add () {
    const stepName = {
        level_id: 0,
        level_quantity: 0
      }
      this.farmer_request.farmer_level.push(stepName)
    },
    remove (key) {
      this.farmer_request.farmer_level.splice(key, 1)
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
      result = await RestApi.putData(warehouseServiceBaseUrl, `${farmerRequestApprove}/${this.id}`, this.farmer_request)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data updated successfully',
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        this.$bvModal.hide('modal-5')
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      } else {
        if (result.error) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async checkList () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(warehouseServiceBaseUrl, farmerRequestCheckList).then(response => {
          if (response) {
            const dataObject = response.data.filter(item => item.status === 0)
            this.checkLists = dataObject
          }
        })
    },
    async getLevelList (warehouseId = null) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-farmer-req-entry/levellist/' + warehouseId).then(response => {
          if (response.success) {
              this.levelList = response.data.map(obj => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.id, text: obj.level_name_bn }
                  } else {
                      return { value: obj.id, text: obj.level_name }
                  }
              })
          }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
