<template>
  <b-container fluid>
    <b-overlay :show="loadingState">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="6" sm="6">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.farmer_id') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ $n(0) }}{{ $n(farmer.farmer_id, { useGrouping: false }) }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.farmer_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getFarmerData(farmer.farmer_id, 'name') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark capitalize">{{ $t('warehouse_service.father_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getFarmerData(farmer.farmer_id, 'father') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark capitalize">{{ $t('globalTrans.division') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDataById(farmer.division_id, 'division') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.region') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDataById(farmer.region_id, 'region') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.district') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDataById(farmer.district_id, 'district') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.upazila') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDataById(farmer.upazilla_id, 'upazila') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.union') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDataById(farmer.union_id, 'union') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark capitalize">{{ $t('warehouse_service.address') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getFarmerData(farmer.farmer_id, 'address') }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" sm="6">
                      <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.warehouse_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ this.$i18n.locale === 'bn' ? farmer.warehouse_name_bn : farmer.warehouse_name }}</p>
                      </b-col>
                    </b-row>
                    <!-- <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.level') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ this.$i18n.locale === 'bn' ? farmer.level_name_bn : farmer.level_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.holding_capacity') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ $n(farmer.level_space) }}</p>
                      </b-col>
                    </b-row> -->
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.crop_type') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDataById(farmer.crop_type_id, 'cropType') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.crop_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDataById(farmer.crop_id, 'cropName') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.sack_number') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ $n(farmer.sack_quantity) }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('warehouse_service.total_quantity') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ $n(farmer.total_quantity) }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.date') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ farmer.store_date | dateFormat }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      </b-overlay>
    </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { farmerList, farmerRequestShow } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFarmerDetails()
      Object.freeze(tmp)
      this.farmer_request = tmp
    }
  },
  data () {
    return {
        farmer: '',
        farmerList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload || this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    async getFarmerDetails () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
      const result = await RestApi.getData(warehouseServiceBaseUrl, `${farmerRequestShow}/${this.$props.id}`)
      if (result.success) {
          this.farmer = result.data
      }
      RestApi.getData(authServiceBaseUrl, farmerList).then(response => {
        if (response.success) {
              this.farmerList = response.data
            }
        })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getFarmerData (farmerId, key = 'name') {
        const farmerdata = this.farmerList.find(item => item.username === farmerId || item.mobile_no === farmerId)

        if (farmerdata) {
            if (key === 'name') {
                return this.$i18n.locale === 'en' ? farmerdata.name : farmerdata.name_bn
            }
            if (key === 'father') {
                return this.$i18n.locale === 'en' ? farmerdata.father_name : farmerdata.father_name_bn
            }
            if (key === 'address') {
                return this.$i18n.locale === 'en' ? farmerdata.address : farmerdata.address_bn
            }
            if (key === 'land') {
                return this.$n(farmerdata.land_area)
            }
        } else {
            return null
        }
    },
    getDataById (Id, type) {
        if (Id) {
            var data = null
            switch (type) {
                case 'division':
                  data = this.$store.state.commonObj.divisionList.find(document => document.value === Id)
                  break
                case 'district':
                  data = this.$store.state.commonObj.districtList.find(document => document.value === Id)
                  break
                case 'upazila':
                  data = this.$store.state.commonObj.upazilaList.find(document => document.value === Id)
                  break
                case 'union':
                  data = this.$store.state.commonObj.unionList.find(document => document.value === Id)
                  break
                case 'region':
                  data = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === Id)
                  break
                case 'warehouse':
                  data = this.$store.state.commonObj.divisionList.find(document => document.value === Id)
                  break
                case 'cropType':
                  data = this.$store.state.warehouse.commodityGroupList.find(document => document.value === Id)
                  break
                case 'cropName':
                  data = this.$store.state.warehouse.commodityNameList.find(document => document.value === Id)
                  break
                default:
                  break
            }
            if (data) {
                return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
            } else {
                return null
            }
        } else {
            return null
        }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
