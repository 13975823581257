<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                  <b-row>
                  <b-col lg="6" sm="6">
                  <ValidationProvider name="Fermer Id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="farmer_id"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.farmer_id')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="farmer_id"
                        v-model="farmer_request.farmer_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        @change="getFarmerInfoList(farmer_request.farmer_id)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback d-block">
                        {{ msg }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Farmer Name">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="farmer_name"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.farmer_name')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="farmer_name"
                        v-model="farmer_info.farmer_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Father Name">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="father_name"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.father_name')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="father_name"
                        v-model="farmer_info.father_name"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Address">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="address"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.address')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="address"
                        v-model="farmer_info.address"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Division" vid='office_name'>
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_name"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('globalTrans.division')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                        id="warehouse_name"
                        readonly
                        v-model="farmer_info.division_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Region">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="region"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.region')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="region_id"
                        v-model="farmer_info.region_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="District">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="district"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.district')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="district_id"
                        v-model="farmer_info.district_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="district"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_config.pre_reqisite_check')}} <span class="text-danger"></span>
                      </template>
                      <ul class="text-dark  list_style">
                          <li class="capitalize" v-for="(item, index) in checkLists" :key="index">{{ $i18n.locale === 'bn' ? item.title_bn : item.title }}</li>
                      </ul>
                    </b-form-group>
                  <!-- <ValidationProvider name="Store Date" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="store_date"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.store_date')}} <span class="text-danger">*</span>
                      </template>
                      <flat-pickr class="form-control"
                          v-model="farmer_request.store_date"
                          :config="{}"
                          placeholder="Select Date"
                      ></flat-pickr>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider> -->
                  <!-- <ValidationProvider name="Store Date" vid="store_date" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.date')"
                      label-for="store_date"
                      slot-scope="{ valid, errors }"
                    >
                    <flat-pickr class="form-control"
                          v-model="farmer_request.store_date"
                          placeholder="Select Date"
                          :state="errors[0] ? false : (valid ? true : null)"
                      ></flat-pickr>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider> -->
                  </b-col>
                  <b-col lg="6" sm="6">
                  <ValidationProvider name="Upazila">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="upazila"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.upazila')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="upazila_id"
                        v-model="farmer_info.upazilla_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Union">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="union"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('globalTrans.union')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                          readonly
                        id="union_id"
                        v-model="farmer_info.union_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                      <ValidationProvider name="Warehouse Name">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="warehouse_name"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.warehouse_name')}} <span class="text-danger"></span>
                      </template>
                        <b-form-input
                          readonly
                        id="warehouse_id"
                        v-model="farmer_info.warehouse_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Crop Type" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_type"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.crop_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="farmer_request.crop_type_id"
                        :options="cropTypeList"
                        id="crop_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Crop Name" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="crop_name"
                      slot-scope="{ valid, errors }"
                      >
                       <template v-slot:label>
                        {{ $t('warehouse_service.crop_name')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="farmer_request.crop_id"
                        :options="cropNameLists"
                        id="upazila_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Sack Number" vid='office_name' rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="sack_number"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{ $t('warehouse_service.sack_number')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="sack_number"
                        type="number"
                        v-model="farmer_request.sack_quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider name="Total Quantity" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="total_quantity"
                      slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{ $t('warehouse_service.total_quantity')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        id="total_quantity"
                        type="number"
                        v-model="farmer_request.total_quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
                  <b-col lg="12" sm="12">
                  <b-row v-for="(setLevel, index) in farmer_request.farmer_level" :key="index">
                  <b-col lg="6" sm="6">
                  <ValidationProvider name="Level" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="level"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('warehouse_service.level')}}<span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        :id="index"
                        v-model="setLevel.level_id"
                        :options="levelList"
                        :state="errors[0] ? false : (valid ? true : null)"
                          @change="getLevelSpace(index)"
                        >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
                  <!-- <b-col lg="3" sm="3">
                  <ValidationProvider name="Level Space" vid='level_space' rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="holding_capacity"
                      slot-scope="{ valid, errors }"
                      >
                      <b-form-input
                          readonly
                        id="holding_capacity"
                        v-model="setLevel.level_space"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col>
                  <b-col lg="4" sm="4">
                   <ValidationProvider name="Level Quantity" vid='level_quantity' rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="holding_capacity"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                        {{ $t('warehouse_service.level_quantity')}} <span class="text-danger"></span>
                      </template>
                      <b-form-input
                        :id="index"
                        v-model="setLevel.level_quantity"
                        :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                  </b-col> -->
                  <b-col lg="1" sm="1">
                      <b-button v-show="index == farmer_request.farmer_level.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i></b-button>
                      <b-button v-show="index || ( !index && farmer_request.farmer_level.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </b-col>
                   </b-row>
                  </b-col>
                  <b-col>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { farmerRequestStore, farmerProfile, farmerRequestCheckList } from '../../api/routes'

export default {
  props: {
       id: {
            default: null,
            type: [String, Number]
        }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFarmerRequestData()
      // Object.freeze(tmp)
      this.farmer_request = tmp
      JSON.parse(this.id)
    }
    this.checkList()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      farmer_request: {
        warehouse_id: '',
        farmer_id: '',
        sack_quantity: '',
        total_quantity: '',
        store_date: '',
        crop_type_id: '0',
        farmer_level: [
            {
            level_id: 0,
            level_quantity: '',
            level_space: ''
            }
        ],
        crop_id: '0',
        status: 0,
        created_by: 1,
        updated_by: 1
      },
      farmer_info: {
        division_id: '',
        region_id: '',
        district_id: '',
        upazilla_id: '',
        union_id: '',
        warehouse_id: '',
        address: '',
        farmer_name: '',
        father_name: '',
        holding_capacity: ''
      },
      cropNameLists: [],
      levelList: [],
      capacity: '',
      district: [],
      checkLists: [],
      msg: '',
      dList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    cropTypeList: function () {
      return this.$store.state.warehouse.commodityGroupList.filter(document => document.status === 0)
    },
    authUser () {
      return this.$store.state.Auth.authUser
    }
  },
  watch: {
    // 'farmer_request.farmer_id': function (newVal, oldVal) {
    //   this.dList = this.getFarmerInfoList(newVal)
    // },
    'farmer_request.crop_type_id': function (newVal, oldVal) {
      this.cropNameLists = this.getCropNameList(newVal)
    },
    'farmer_request.warehouse_id': function (newVal, oldVal) {
      this.levelList = this.getLevelList(newVal)
    }
  },
  methods: {
    add () {
      const stepName = {
        level_id: 0,
        level_quantity: ''
      }
      this.farmer_request.farmer_level.push(stepName)
    },
    remove (key) {
      this.farmer_request.farmer_level.splice(key, 1)
    },
    async getLevelSpace (index) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/list-all').then(response => {
        if (response.success) {
          const levelId = this.farmer_request.farmer_level[index].level_id
          const dataObject = response.data.filter(document => document.id === levelId)
         // this.farmer_request.farmer_level[index].level_space = dataObject[0].level_space
         this.$set(this.farmer_request.farmer_level[index], 'level_space', dataObject[0].level_space)
        }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getFarmerRequestData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
      result = await RestApi.postData(warehouseServiceBaseUrl, farmerRequestStore, this.farmer_request)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.farmer_request.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        this.$bvModal.hide('modal-4')
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      } else {
        if (result.error) {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async getLevelList (warehouseId = null) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-farmer-req-entry/levellist/' + warehouseId).then(response => {
        if (response.success) {
            this.levelList = response.data.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.id, text: obj.level_name_bn }
                } else {
                    return { value: obj.id, text: obj.level_name }
                }
            })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getCropNameList (cropTypeId = null) {
      const cropList = this.$store.state.warehouse.commodityNameList.filter(document => document.status === 0)
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    },
    async getFarmerInfoList (farmerId = null) {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        await RestApi.getData(authServiceBaseUrl, farmerProfile + farmerId).then(response => {
        if (response.success) {
            if ((this.authUser.warehouse_id && this.authUser.warehouse_id === response.data.warehouse_id) || this.authUser.warehouse_id >= 0) {
                this.msg = ''
                this.farmer_info.division_id = this.getDataById(response.data.division_id, 'division')
                this.farmer_info.region_id = this.getDataById(response.data.region_id, 'region')
                this.farmer_info.district_id = this.getDataById(response.data.district_id, 'district')
                this.farmer_info.upazilla_id = this.getDataById(response.data.upazilla_id, 'upazila')
                this.farmer_info.union_id = this.getDataById(response.data.union_id, 'union')
                this.farmer_info.warehouse_id = this.getDataById(response.data.warehouse_id, 'warehouse')
                if (this.$i18n.locale === 'bn') {
                    this.farmer_info.address = response.data.address_bn
                    this.farmer_info.farmer_name = response.data.name_bn
                    this.farmer_info.father_name = response.data.father_name_bn
                    this.farmer_request.warehouse_id = response.data.warehouse_id
                } else {
                    this.farmer_info.address = response.data.address
                    this.farmer_info.farmer_name = response.data.name
                    this.farmer_info.father_name = response.data.father_name
                    this.farmer_request.warehouse_id = response.data.warehouse_id
                }
            }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        } else {
            this.msg = 'Farmer Not Found!'
            this.farmer_info.division_id = ''
            this.farmer_info.region_id = ''
            this.farmer_info.district_id = ''
            this.farmer_info.upazilla_id = ''
            this.farmer_info.union_id = ''
            this.farmer_info.warehouse_id = ''
            this.farmer_info.address = ''
            this.farmer_info.farmer_name = ''
            this.farmer_info.father_name = ''
            this.farmer_request.warehouse_id = ''
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      })
    },
    async checkList () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(warehouseServiceBaseUrl, farmerRequestCheckList).then(response => {
            if (response.success) {
                const dataObject = response.data.filter(item => item.status === 0)
                this.checkLists = dataObject
            }
        })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDataById (Id, type) {
        if (Id) {
            var data = null
            switch (type) {
                case 'division':
                  data = this.$store.state.commonObj.divisionList.find(document => document.value === Id)
                  break
                case 'district':
                  data = this.$store.state.commonObj.districtList.find(document => document.value === Id)
                  break
                case 'upazila':
                  data = this.$store.state.commonObj.upazilaList.find(document => document.value === Id)
                  break
                case 'union':
                  data = this.$store.state.commonObj.unionList.find(document => document.value === Id)
                  break
                case 'region':
                  data = this.$store.state.warehouse.regionList.find(document => document.value === Id)
                  break
                case 'warehouse':
                  data = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === Id)
                  break
                case 'cropType':
                  data = this.$store.state.warehouse.commodityGroupList.find(document => document.value === Id)
                  break
                case 'cropName':
                  data = this.$store.state.warehouse.commodityNameList.find(document => document.value === Id)
                  break
                default:
                  break
            }
            if (data) {
                return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
            } else {
                return null
            }
        } else {
            return null
        }
    }
  }
}
</script>
<style>
  .list_style {
    list-style-type: square !important
  }
</style>
