<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
      <b-row v-if="!authUser.warehouse_id">
          <b-col md="6" sm="12" xs="12">
          <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_service.crop_type')"
              label-for="crop_type"
              >
              <b-form-select
                plain
                v-model="search.crop_type_id"
                :options="cropTypeList"
                id="crop_type_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12" xs="12">
          <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_service.crop_name')"
              label-for="crop_name"
              >
              <b-form-select
                plain
                v-model="search.crop_id"
                :options="cropNameLists"
                id="crop_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!authUser.warehouse_id">
        <b-col md="6" sm="12" xs="12">
            <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('globalTrans.from_date')"
            label-for="from_date"
            >
            <flat-pickr class="form-control"
                    v-model="search.from_date"
                    :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            <!-- <b-form-input
                id="from_date"
                type="date"
                v-model="search.from_date"
                placeholder=""
                >
            </b-form-input> -->
            </b-form-group>
        </b-col>
        <b-col md="6" sm="12" xs="12">
            <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('globalTrans.to_date')"
            label-for="to_date"
            >
            <flat-pickr class="form-control"
                    v-model="search.to_date"
                    :placeholder="$t('globalTrans.select_date')"
                ></flat-pickr>
            </b-form-group>
        </b-col>
      </b-row>
     <b-row>
          <b-col md="6" sm="12" xs="12" v-if="authUser.warehouse_id">
            <b-form-group
            class="row"
            label-cols-sm="3"
            label-for="commodity_group_id"
            :label="$t('warehouse_information.warehouse_name')"
            >
            <b-form-select
              plain
              v-model="search.warehouse_id"
              :options="warehouseList"
              id="commodity_group_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
              <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service.farmer_request') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
<!--                <template v-if="listData.length && farmerList.length">-->
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span class="capitalize">
                          {{ getDataById(data.item.division_id, 'division') }}
                        </span>
                    </template>
                    <template v-slot:cell(region_name)="data">
                      <span class="capitalize">
                          {{ getDataById(data.item.region_id, 'region') }}
                        </span>
                    </template>
                    <template v-slot:cell(district_name)="data">
                      <span class="capitalize">
                          {{ getDataById(data.item.district_id, 'district') }}
                        </span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(farmer_id)="data">
                        <span class="capitalize">
                          {{ $n(0) }}{{ $n(data.item.farmer_id, { useGrouping: false }) }}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_name)="data">
                        <span class="capitalize">
                          {{ getFarmerData(data.item.farmer_id, 'name') }}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_father_name)="data">
                      <span class="capitalize">
                          {{ getFarmerData(data.item.farmer_id, 'father') }}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_land_area)="data">
                      {{ getFarmerData(data.item.farmer_id, 'land') }}
                    </template>
                    <template v-slot:cell(store_date)="data">
                      <span class="capitalize">{{ data.item.store_date | dateFormat }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span v-if="data.item.status === 2" class="text-danger">{{ data.item.status ? $t('globalTrans.rejected') : $t('globalTrans.approved') }}</span>
                      <span v-else>{{ data.item.status ? $t('globalTrans.pending') : $t('globalTrans.approved') }}</span>
                      <span v-if="data.item.status === 0"><b-button v-b-modal.modal-6 variant=" iq-bg-success mr-1 mb-1" @click="view(data.item)"><small>{{ $t('warehouse_service.qcCertificate') }}</small></b-button></span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" v-b-modal.modal-7 class="btn_table_action table_action_view" title="View Details" @click="view(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" v-if="(itemStatus(data.item) === 1)" v-b-modal.modal-5 class="btn_table_action table_action_status" title="Click to Approve" @click="approv(data.item)"><i class="fas fa-check"></i></a>
                      <a href="javascript:" v-if="(itemStatus(data.item) === 1)" v-b-modal.modal-3 class="btn_table_action table_action_toggle" title="Click to Reject" @click="view(data.item)"><i class="fas fa-times-circle"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
<!--                </template>-->
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-7" size="lg" :title="$t('warehouse_service.farmer_request_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <Detail :id="testId" :key="testId"/>
      </p>
    </b-modal>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>
    <b-modal id="modal-3" size="md" :title="formTitleReject" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormR :id="testId" :key="testId"/>
      </p>
    </b-modal>
    <b-modal id="modal-5" size="md" :title="formTitleApprove" :ok-title="$t('globalTrans.close')" ok-only ok-variant="success">
      <p>
        <FormA :id="testId" :warehouse_id="warehouse" :key="testId"/>
      </p>
    </b-modal>
    <b-modal id="modal-6" size="lg" :title="qcCertificate" :ok-title="$t('globalTrans.close')" ok-only ok-variant="success">
      <p>
        <QC :id="testId" :key="testId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>

import FormV from './Form'
import FormR from './Reject'
import FormA from './Approve'
import Detail from './Details'
import QC from './QC'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { farmerRequestList, farmerList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  name: 'UiDataTable',
  components: {
    FormV, Detail, FormR, FormA, QC
  },
  data () {
    return {
      search: {
        crop_type_id: '0',
        crop_id: '0',
        from_date: '',
        to_date: ''
      },
      testId: 0,
      warehouse: 0,
      rows: [],
      cropNameLists: [],
      farmerList: [],
      checkLists: [],
      html: ''
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    formTitle () {
       return this.$t('warehouse_service.farmer_request') + ' ' + this.$t('globalTrans.entry')
    },
    formTitleReject () {
        return this.$t('globalTrans.rejectMsg')
    },
    formTitleApprove () {
        return this.$t('globalTrans.approveMsg')
    },
    qcCertificate () {
        return this.$t('warehouse_service.qcCertificate')
    },
    cropTypeList: function () {
      return this.$store.state.warehouse.commodityGroupList.filter(document => document.status === 0)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('globalTrans.division'), class: 'text-center' },
          { label: this.$t('globalTrans.region'), class: 'text-center' },
         { label: this.$t('globalTrans.district'), class: 'text-center' },
         { label: this.$t('warehouse_service.warehouse'), class: 'text-center' },
        { label: this.$t('warehouse_service.farmer_id'), class: 'text-center' },
         { label: this.$t('warehouse_service.farmer_name'), class: 'text-center' },
         { label: this.$t('warehouse_service.father_name'), class: 'text-center' },
         { label: this.$t('warehouse_service.landarea'), class: 'text-center' },
         { label: this.$t('globalTrans.date'), class: 'text-center' },
         { label: this.$t('globalTrans.status'), class: 'text-center' },
         { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'district_name' },
          { key: 'warehouse_name_bn' },
          { key: 'farmer_id' },
          { key: 'farmer_name' },
          { key: 'farmer_father_name' },
          { key: 'farmer_land_area' },
          { key: 'store_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'district_name' },
          { key: 'warehouse_name' },
          { key: 'farmer_id' },
          { key: 'farmer_name' },
          { key: 'farmer_father_name' },
          { key: 'farmer_land_area' },
          { key: 'store_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.crop_type_id': function (newVal, oldVal) {
      this.cropNameLists = this.getCropNameList(newVal)
    }
  },
  created () {
    this.loadData()
    // this.checkList()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        region_id: '',
        district_id: '',
        warehouse_id: '',
        farmer_id: '',
        crop_id: '',
        level_id: '',
        total_quantity: ''
      }
    },
    searchData () {
      this.loadData()
    },
    view (item) {
      this.testId = item.id
    },
    approv (item) {
        this.testId = item.id
        this.warehouse = item.warehouse_id
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
       this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
       try {
           const response = await RestApi.getData(authServiceBaseUrl, farmerList)
            if (response.success) {
                this.farmerList = response.data
            }
            if (this.farmerList.length) {
                const result = await RestApi.getData(warehouseServiceBaseUrl, farmerRequestList, params)
                if (result.success) {
                    this.$store.dispatch('setList', result.data.data)
                    this.paginationData(result.data)
                }
            }
        } catch (error) {
            this.$toast.error({
            title: 'Error',
            message: 'Please, try again.',
            color: '#FFE09B'
            })
        }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getCropNameList (cropTypeId = null) {
    const cropList = this.$store.state.warehouse.commodityNameList.filter(document => document.status === 0)
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    },
     itemStatus (item) {
      return item.status
    },
    getFarmerData (farmerId, key = 'name') {
        const farmerdata = this.farmerList.find(item => item.username === farmerId || item.mobile_no === farmerId)
        if (farmerdata) {
            if (key === 'name') {
                return this.$i18n.locale === 'en' ? farmerdata.name : farmerdata.name_bn
            }
            if (key === 'father') {
                return this.$i18n.locale === 'en' ? farmerdata.father_name : farmerdata.father_name_bn
            }
            if (key === 'land') {
                return this.$n(farmerdata.land_area)
            }
        } else {
            return null
        }
    },
    getDataById (Id, type) {
        if (Id) {
            var data = null
            switch (type) {
                case 'division':
                  data = this.$store.state.commonObj.divisionList.find(document => document.value === Id)
                  break
                case 'district':
                  data = this.$store.state.commonObj.districtList.find(document => document.value === Id)
                  break
                case 'upazila':
                  data = this.$store.state.commonObj.upazilaList.find(document => document.value === Id)
                  break
                case 'union':
                  data = this.$store.state.commonObj.unionList.find(document => document.value === Id)
                  break
                case 'region':
                  data = this.$store.state.warehouse.regionList.find(document => document.value === Id)
                  break
                case 'warehouse':
                  data = this.$store.state.warehouse.warehouseInfoList.find(document => document.value === Id)
                  break
                case 'cropType':
                  data = this.$store.state.warehouse.commodityGroupList.find(document => document.value === Id)
                  break
                case 'cropName':
                  data = this.$store.state.warehouse.commodityNameList.find(document => document.value === Id)
                  break
                default:
                  break
            }
            if (data) {
                return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
            } else {
                return null
            }
        } else {
            return null
        }
    }
  }
}
</script>
