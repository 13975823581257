<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <template>
                  {{ $t('globalTrans.selectList')}}<span class="text-danger">*</span><br><br>
              </template>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="12" sm="12" md="12">
                        <ValidationProvider name="Pre-requsite List" rules="required">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="level"
                            slot-scope="{ valid, errors }"
                            >
                            <b-form-checkbox-group
                                v-model="prerequisit"
                                :options="checkLists"
                                :state="errors[0] ? false : (valid ? true : null)"
                                stacked
                            ></b-form-checkbox-group>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-3')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl } from '../../../../../config/api_config'
import { farmerRequestReject, farmerRequestCheckList } from '../../api/routes'

export default {
  props: {
    id: {
      default: null,
      type: [String, Number]
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    await this.checkList()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.yes'),
      prerequisit: [],
      dList: [],
      checkLists: [],
      isLoading: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    }
  },
  methods: {
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
      result = await RestApi.putData(warehouseServiceBaseUrl, `${farmerRequestReject}/${this.id}`, this.prerequisit)
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: 'Data updated successfully',
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        this.$bvModal.hide('modal-3')
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      } else {
        if (result.error) {
            this.$refs.form.setErrors(result.errors)
            this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
            })
        } else {
            this.$toast.error({
            title: 'Error',
            message: result.message
            })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
    async checkList () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(warehouseServiceBaseUrl, farmerRequestCheckList).then(response => {
        if (response) {
          // const dataObjectTemp = response.filter(document => document.region_id === regionId)
          const dataObject = response.data.filter(item => item.status === 0)
          this.checkLists = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.title_bn }
            } else {
                return { value: obj.id, text: obj.title }
            }
          })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        } else {
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
      })
    }

  }
}
</script>
